/* You can add global styles to this file, and also import other style files */
//----------------------------------*\
// Fonts
//----------------------------------*/
@import 'assets/fonts/fonts.scss';

//* {
//    font-family: 'Pretendard', sans-serif;
//}

* {
    font-family: 'Pretendard', sans-serif;
}
html {
    font-size: 62.5%;
}
body {
    font-family: 'Pretendard', sans-serif;
    font-weight: 500;
    margin: 0;
    font-style: normal;
    font-size: 1.3rem;
    color: var(--font-color);
}

// Colors
:root {
    --white: #ffffff;
    --black: #000000;

    // gray
    --gray-100: #2f2d2d;
    --gray-90: #575555;
    --gray-80: #848382;
    --gray-70: #bcbaba;
    --gray-60: #d8d8d8;
    --gray-50: #eeeeee;
    --gray-40: #f1f0ef;
    --gray-30: #faf9f9;

    // red
    --red-200: #d3243b;
    --red-100: #e34a5e;
    --red-20: #f9dbdf;
    --red-10: #fdedef;
    --red-5: #fdf6f7;

    // label
    --label-yellow: #ffe885;
    --label-green: #bbe17b;
    --label-skyblue: #bbeaff;
    --label-lavender: #a2bdfc;
    --label-pink: #ffa5c1;
    --label-orange: #f8b990;
    --label-mint: #6ad2c0;
    --label-turquoise: #6b9cb2;
    --label-purple: #9289d2;
    --label-red: #eb7c7c;

    // state
    --state-error-100: #c2273b;
    --state-error-10: #f9eaec;
    --state-error-5: #fcf4f5;

    --state-success-100: #0d755e;
    --state-success-10: #e7f2ef;
    --state-success-5: #f3f8f7;

    --state-warning-100: #c96b12;
    --state-warning-10: #faf1e8;
    --state-warning-5: #fcf7f3;

    --state-etc-100: #3457b1;
    --state-etc-10: #ebeff8;
    --state-etc-5: #f5f6fb;

    --font-color: #141517;
    --background-color: #faf9f9;

    // etc
    //--main-menu-nav-width: 80px;
}

// Typography
@mixin rw-type-b($fontSize: 3.2rem, $lineHeight: 44px, $color: var(--font-color)) {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 700;
    font-size: $fontSize;
    line-height: $lineHeight;
    color: $color;
}
@mixin rw-type-b-woc($fontSize: 3.2rem, $lineHeight: 44px) {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 700;
    font-size: $fontSize;
    line-height: $lineHeight;
}
@mixin rw-type-r($fontSize: 1.8rem, $lineHeight: 29px, $color: var(--font-color)) {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 400;
    font-size: $fontSize;
    line-height: $lineHeight;
    color: $color;
}
@mixin rw-type-r-woc($fontSize: 1.8rem, $lineHeight: 29px) {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 400;
    font-size: $fontSize;
    line-height: $lineHeight;
}

.rw-type-b32 {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 700;
    font-size: 3.2rem;
    line-height: 44px;
    color: var(--font-color);
}
.rw-type-b27 {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 700;
    font-size: 2.7rem;
    line-height: 39px;
    color: var(--font-color);
}
.rw-type-b20 {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    line-height: 31px;
    color: var(--font-color);
}
.rw-type-b17 {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 700;
    font-size: 1.7rem;
    line-height: 28px;
    color: var(--font-color);
}
.rw-type-b15 {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 23px;
    color: var(--font-color);
}
.rw-type-r17 {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 400;
    font-size: 1.7rem;
    line-height: 28px;
    color: var(--font-color);
}
.rw-type-r15 {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 23px;
    color: var(--font-color);
}
.rw-type-r14 {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 19px;
    color: var(--font-color);
}

// shadows
.layout-shadow {
    box-shadow: 0 5px 18px #efeeed;
}
.modal-shadow {
    box-shadow: 0 5px 18px #c6c6c6;
}
.mini-modal-shadow {
    box-shadow: 0 3px 14px #e0e0e0;
}
@mixin folder-shadow($color: rgba(235, 124, 124, 0.3)) {
    box-shadow: 0 3px 6px $color;
}
.locker-and-card-shadow {
    box-shadow: 1px 2px 4px #f2f2f2;
}
.move-locker-shadow {
    box-shadow: 1px 1px 13px 3px #c7c7c7;
}
.card-shadow {
    box-shadow: 0 1px 7px 1px #e1e1e1;
}
.upper-bar-shadow {
    box-shadow: 0 2px 10px #efeeed;
}
.lower-bar-shadow {
    box-shadow: 0 -2px 15px #efeeed;
}

@mixin layout-shadow {
    box-shadow: 0 5px 18px #efeeed;
}
@mixin modal-shadow {
    box-shadow: 0 5px 18px #c6c6c6;
}
@mixin mini-modal-shadow {
    box-shadow: 0 3px 14px #e0e0e0;
}
@mixin locker-and-card-shadow {
    box-shadow: 1px 2px 4px #f2f2f2;
}
@mixin move-locker-shadow {
    box-shadow: 1px 1px 13px 3px #c7c7c7;
}
@mixin card-shadow {
    box-shadow: 0 1px 7px 1px #e1e1e1;
}
@mixin upper-bar-shadow {
    box-shadow: 0 2px 10px #efeeed;
}
@mixin lower-bar-shadow {
    box-shadow: 0 -2px 15px #efeeed;
}
@mixin table-width-scroll-shadow {
    box-shadow: 5px 0 5px -2px #e1e1e1;
}

// REDWHALE

.full-fixed {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.full-absolute {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.b-1 {
    border: 1px solid black;
    box-sizing: border-box;
}

.thin-scroll-y-overlay {
    overflow-y: overlay;

    /* width */
    &::-webkit-scrollbar {
        width: 7px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        // box-shadow: inset 0 0 5px grey;
        background-color: transparent;
        border-radius: 15px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        border: 2px solid transparent;
        background-clip: padding-box;
        background-color: var(--gray-60);
        border-radius: 30px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background-color: var(--gray-100);
    }

    &::-webkit-scrollbar-corner {
        background-color: transparent;
    }
}

.thin-scroll-x-overlay {
    overflow-x: overlay;

    /* width */
    &::-webkit-scrollbar {
        height: 7px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        // box-shadow: inset 0 0 5px grey;
        background-color: transparent;
        border-radius: 15px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        border: 2px solid transparent;
        background-clip: padding-box;
        background-color: var(--gray-60);
        border-radius: 30px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background-color: var(--gray-100);
    }

    &::-webkit-scrollbar-corner {
        background-color: transparent;
    }
}

.thin-scroll-y {
    overflow-y: auto;

    /* width */
    &::-webkit-scrollbar {
        width: 7px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        // box-shadow: inset 0 0 5px grey;
        background-color: transparent;
        border-radius: 15px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        border: 2px solid transparent;
        background-clip: padding-box;
        background-color: var(--gray-60);
        border-radius: 30px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background-color: var(--gray-100);
    }

    &::-webkit-scrollbar-corner {
        background-color: transparent;
    }
}

.thin-scroll-x {
    overflow-x: auto;

    /* width */
    &::-webkit-scrollbar {
        height: 7px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        // box-shadow: inset 0 0 5px grey;
        background-color: transparent;
        border-radius: 15px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        border: 2px solid transparent;
        background-clip: padding-box;
        background-color: var(--gray-60);
        border-radius: 30px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background-color: var(--gray-100);
    }

    &::-webkit-scrollbar-corner {
        background-color: transparent;
    }
}

.scroll-y {
    overflow-y: auto;

    /* width */
    &::-webkit-scrollbar {
        width: 16px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        // box-shadow: inset 0 0 5px grey;
        background-color: transparent;
        border-radius: 15px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        border: 5px solid transparent;
        background-clip: padding-box;
        background-color: var(--gray-60);
        border-radius: 30px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background-color: var(--gray-100);
    }

    &::-webkit-scrollbar-corner {
        background-color: transparent;
    }
}

.scroll-x {
    overflow-x: auto;

    /* width */
    &::-webkit-scrollbar {
        height: 16px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        // box-shadow: inset 0 0 5px grey;
        background-color: transparent;
        border-radius: 15px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        border: 5px solid transparent;
        background-clip: padding-box;
        background-color: var(--gray-60);
        border-radius: 30px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background-color: var(--gray-100);
    }

    &::-webkit-scrollbar-corner {
        background-color: transparent;
    }
}

.scroll-y-overlay {
    overflow-y: overlay;

    /* width */
    &::-webkit-scrollbar {
        width: 16px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        // box-shadow: inset 0 0 5px grey;
        background-color: transparent;
        border-radius: 15px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        border: 5px solid transparent;
        background-clip: padding-box;
        background-color: var(--gray-60);
        border-radius: 30px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background-color: var(--gray-100);
    }

    &::-webkit-scrollbar-corner {
        background-color: transparent;
    }
}

.scroll-x-overlay {
    overflow-x: overlay;

    /* width */
    &::-webkit-scrollbar {
        height: 16px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        // box-shadow: inset 0 0 5px grey;
        background-color: transparent;
        border-radius: 15px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        border: 5px solid transparent;
        background-clip: padding-box;
        background-color: var(--gray-60);
        border-radius: 30px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background-color: var(--gray-100);
    }

    &::-webkit-scrollbar-corner {
        background-color: transparent;
    }
}

ng-scrollbar {
    &.rw-scroll {
        --scrollbar-size: 7px;
        --scrollbar-thumb-color: var(--gray-60);
        --scrollbar-thumb-hover-color: var(--gray-100);
        --scrollbar-border-radius: 10px;
        --scrollbar-thumb-transition: height ease-out 0ms, width ease-out 0ms;
        --scrollbar-track-transition: height ease-out 0ms, width ease-out 0ms;
        //border: 1px solid gray;
    }
    &.rw-scroll-middle {
        --scrollbar-size: 7px;
        --scrollbar-thumb-color: var(--gray-60);
        --scrollbar-thumb-hover-color: var(--gray-100);
        --scrollbar-border-radius: 10px;
        --scrollbar-thumb-transition: height ease-out 0ms, width ease-out 0ms;
        --scrollbar-track-transition: height ease-out 0ms, width ease-out 0ms;
        //border: 1px solid gray;
    }
    &.rw-scroll-small {
        --scrollbar-size: 7px;
        --scrollbar-thumb-color: var(--gray-60);
        --scrollbar-thumb-hover-color: var(--gray-100);
        --scrollbar-border-radius: 10px;
        --scrollbar-thumb-transition: height ease-out 0ms, width ease-out 0ms;
        --scrollbar-track-transition: height ease-out 0ms, width ease-out 0ms;
        //border: 1px solid gray;
    }
}

.w-100 {
    width: 100% !important;
}

.h-100 {
    height: 100% !important;
}

.pos-rel {
    position: relative;
}

.user-select-none {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.display-block {
    display: block !important;
}

.display-flex {
    display: flex !important;
}

.display-none {
    display: none !important;
}
// Material Design Icon
.mdi {
    display: flex;
}

.cursor-pointer {
    cursor: pointer;
}

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
}
// $line-cnt : 라인 수
// $line-height : line-height값
// 사용법 : @include ellipsis(3, 1.6em);
@mixin ellipsis($line-cnt, $line-height) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $line-cnt;
    word-break: break-all;
    /* 라인수 */
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    line-height: $line-height;
    height: $line-height * $line-cnt;
    /* line-height 가 1.2em 이고 3라인을 자르기 때문에 height는 1.2em * 3 = 3.6em */
}
.line-ellipsis-one {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    word-break: break-all;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    word-wrap: break-word;
}
.line-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    word-wrap: break-word;
    word-break: break-all;
    // line-break: anywhere;
}
.ellipsis-dropdown {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px 9px 4px;
    background-color: var(--gray-100);
    border: 1px solid #f1f1f1;
    @include locker-and-card-shadow();
    border-radius: 10px;
    line-break: anywhere;
    @include rw-type-r(1.5rem, 23px, var(--white));

    position: absolute;
    width: 100%;
}
[data-two-line-ellipsis] {
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    word-wrap: break-word;
    word-break: break-all;

    &:hover::before {
        content: attr(data-two-line-ellipsis);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 13px;
        background: #ffffff;
        border: 1px solid #f1f1f1;
        box-shadow: 0 0 10px rgba(181, 181, 181, 0.109804);
        border-radius: 5px;

        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        letter-spacing: -0.144px;
        color: #212121;

        position: absolute;
        width: 100%;
        bottom: 5px;
    }
}

// responsive

@mixin rw-tablet-sm {
    @media (min-width: 1px) {
        @content;
    }
}
@mixin rw-tablet-md {
    @media (min-width: 768px) {
        @content;
    }
}
@mixin rw-tablet-lg {
    @media (min-width: 1280px) {
        @content;
    }
}

@mixin rw-pc-sm {
    @media (min-width: 1440px) {
        @content;
    }
}
@mixin rw-pc-md {
    @media (min-width: 1536px) {
        @content;
    }
}
@mixin rw-pc-lg {
    @media (min-width: 1920px) {
        @content;
    }
}
@mixin rw-pc-lx {
    @media (min-width: 2560px) {
        @content;
    }
}

// other
// css init
@mixin css-init {
    padding: 0;
    margin: 0;
    text-align: initial;
}

// transition
@mixin opacity-ani-modal {
    transition: opacity 0.3s cubic-bezier(0.25, 0.8, 0.25, 0.8);
    -moz-transition: opacity 0.3s cubic-bezier(0.25, 0.8, 0.25, 0.8);
    -webkit-transition: opacity 0.3s cubic-bezier(0.25, 0.8, 0.25, 0.8);
    -o-transition: opacity 0.3s cubic-bezier(0.25, 0.8, 0.25, 0.8);
}

@mixin transition($prop, $duration, $easing_fn: linear, $delay: 0s) {
    transition: $prop $duration $easing_fn $delay;
    -moz-transition: $prop $duration $easing_fn $delay;
    -webkit-transition: $prop $duration $easing_fn $delay;
    -o-transition: $prop $duration $easing_fn $delay;
}

// icon
@mixin icon($url) {
    background-image: url($url);
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
}
@mixin icon2($url, $width, $height) {
    background-image: url($url);
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: contain;
    width: $width;
    height: $height;
}

.close-icon {
    background-image: url('/assets/icons/etc/x-bold.svg');
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
}
.list-bold-icon {
    background-image: url('/assets/icons/etc/list-bold.svg');
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
}
.trash-icon {
    background-image: url('/assets/icons/etc/trash.svg');
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: contain;
    width: 22px;
    height: 22px;
}
.user-list-icon {
    box-sizing: border-box;
    background-image: url('/assets/icons/side_bar/user-list.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
}
.chat-circle-dots-icon {
    box-sizing: border-box;
    background-image: url('/assets/icons/main_menu/chat-circle-dots.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
}
.gear-icon {
    box-sizing: border-box;
    background-image: url('/assets/icons/etc/gear.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
}
.three-dots-icon {
    box-sizing: border-box;
    background-image: url('/assets/icons/etc/dots-three.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
}
.user-circle-icon {
    background-image: url('/assets/icons/main_menu/user-circle.svg');
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
}
.arrow-fat-line-up-icon {
    background-image: url('/assets/icons/main_menu/arrow-fat-line-up.svg');
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
}
.at-icon {
    background-image: url('/assets/icons/etc/at-duotone.svg');
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: contain;
    width: 18px;
    height: 18px;
}
.caret-down-1-icon {
    background-image: url('/assets/icons/arrow/caret-down-1.svg');
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: contain;
    width: 20px;
    height: 20px;
}
.caret-right-1-icon {
    background-image: url('/assets/icons/arrow/caret-right-1.svg');
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: contain;
    width: 20px;
    height: 20px;
}
.caret-right-1-g60-icon {
    background-image: url('/assets/icons/arrow/caret-right-1-g60.svg');
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: contain;
    width: 20px;
    height: 20px;
}
.caret-right-1-g80-icon {
    background-image: url('/assets/icons/arrow/caret-right-1-g80.svg');
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: contain;
    width: 16px;
    height: 16px;
}
.caret-circle-down-icon {
    background-image: url('/assets/icons/arrow/caret-circle-down.svg');
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
}
.calendar-icon {
    background-image: url('/assets/icons/etc/calendar.svg');
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
}
.file-download-icon--red {
    background-image: url('/assets/icons/etc/file-arrow-down-red.svg');
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: contain;
    width: 22px;
    height: 22px;
}
.caret-up-1-icon--red {
    background-image: url('/assets/icons/arrow/caret-up-1-red.svg');
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: contain;
    width: 22px;
    height: 22px;
}
.plus-bold {
    background-image: url('/assets/icons/etc/plus-bold.svg');
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: contain;
    width: 22px;
    height: 22px;
}
.plus-bold-gray70 {
    background-image: url('/assets/icons/etc/plus-bold-gray-70.svg');
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: contain;
    width: 14px;
    height: 14px;
}
.hand-waving-icon {
    background-image: url('/assets/icons/etc/hand-waving.svg');
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: contain;
    width: 22px;
    height: 22px;
}
.x-bold-icon {
    background-image: url('/assets/icons/etc/x-bold.svg');
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: contain;
    width: 22px;
    height: 22px;
}
.monitor-duotone-icon {
    background-image: url('/assets/icons/etc/monitor-duotone.svg');
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: contain;
    width: 22px;
    height: 22px;
}
.phone-icon {
    background-image: url('/assets/icons/etc/phone.svg');
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: contain;
    width: 18px;
    height: 18px;
}
.signpost-duotone-icon {
    background-image: url('/assets/icons/etc/signpost-duotone.svg');
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: contain;
    width: 18px;
    height: 18px;
}
.info-icon {
    background-image: url('/assets/icons/etc/info.svg');
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: contain;
    width: 20px;
    height: 20px;
}
.card-icon {
    background-image: url('/assets/icons/etc/card.svg');
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: contain;
    width: 43px;
    height: 30px;
    overflow: hidden;
    border-radius: 5px;
}
.ellipse-icon {
    background-image: url('/assets/icons/etc/ellipse.svg');
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: contain;
    width: 4.3px;
    height: 4px;
}

// Tooltip
.rw-tooltip {
    position: fixed;
    top: 0;
    left: 0;
    max-width: 200px;
    padding: 6px 9px 4px !important;
    font-family: 'Pretendard';
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 23px;
    text-align: center;
    color: var(--white);
    overflow-wrap: break-word;
    background-color: var(--gray-100);
    border-radius: 10px;
    opacity: 0;
    z-index: 9999;
}

.rw-tooltip-show {
    opacity: 1;
}

.rw-tooltip-top {
    &:before {
        content: '';
        position: absolute;
        left: 50%;
        border-style: solid;
        //top: 100%;
        //margin-left: -5px;
        //border-width: 5px;
        //border-color: var(--gray-100) transparent transparent transparent;

        top: 85%;
        border-width: 4px;
        border-color: var(--gray-100) transparent transparent var(--gray-100);
        transform: rotateZ(225deg);
        border-radius: 2px;
    }
}

.rw-tooltip-right {
    &:before {
        content: '';
        position: absolute;
        top: calc(50% + 1px);
        margin-top: -5px;
        border-style: solid;

        left: -4px;
        border-width: 4px;
        border-color: var(--gray-100) var(--gray-100) transparent transparent;
        transform: rotateZ(225deg);
        border-radius: 2px;
    }
}

.rw-tooltip-bottom {
    &:before {
        content: '';
        position: absolute;
        margin-left: -5px;
        left: 50%;
        border-style: solid;
        //bottom: 100%;
        //border-width: 5px;
        //border-color: transparent transparent var(--gray-100) transparent;

        bottom: 85%;
        border-width: 4px;
        border-color: transparent var(--gray-100) var(--gray-100) transparent;
        transform: rotateZ(225deg);
        border-radius: 2px;
    }
    &-right {
        &:before {
            content: '';
            position: absolute;
            left: 80%;
            margin-left: -5px;
            border-style: solid;
            //bottom: 100%;
            //border-width: 5px;
            //border-color: transparent transparent var(--gray-100) transparent;

            bottom: 85%;
            border-width: 4px;
            border-color: transparent var(--gray-100) var(--gray-100) transparent;
            transform: rotateZ(225deg);
            border-radius: 2px;
        }
    }
}

.rw-tooltip-left {
    &:before {
        content: '';
        position: absolute;
        top: calc(50% - 0px);
        border-style: solid;
        //margin-top: -5px;
        //left: 100%;
        //border-width: 5px;
        //border-color: transparent transparent transparent var(--gray-100);

        right: -4px;
        border-width: 4px;
        margin-top: -4px;
        border-color: transparent transparent var(--gray-100) var(--gray-100);
        transform: rotateZ(225deg);
        border-radius: 2px;
    }
}
